import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import posterWText from "../../images/posters/Everyelsewhere EP poster text.jpg";
import posterNoText from "../../images/posters/Everyelsewhere EP poster no text.jpg";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Everyelsewhere EP" />

		<h2>Everyelsewhere EP</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST03 everyelsewhere ep front.jpg"
			alt="Jesse Rivest - Everyelsewhere EP - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
		To be released on February 1, 2011.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			<span className="italic">Everyelsewhere EP</span> is nearly completely New Zealand made;
			you have to consider that I am Canadian and most of these songs started
			in Canada. For the past few years I've wanted to release some music in
			NZ; at last, I was ready and something came together with this group of
			fine folks—the people involved in this EP—and to them I am grateful
			for their time, energy, and friendship. A few favourite moments along
			the way were the times shared with them: Brendan, Peter and I cooling
			our nerves during recording with delicious scotch and baked goodies; Stu
			and I facing each other and rocking out with headphones in my room;
			Warwick and I wiring tracks through cool analog gear; Declan and I
			listening and mastering in supreme hi-fi delight in secret hideaways.
			These songs have been around for some time now; for me this EP is about
			the process of producing music and working with others. Three of the
			songs were recorded in live takes while two were studio developments;
			the compilation celebrates my ongoing development as a musician,
			songwriter, and producer of music.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>The Romance of Kaslo (4:53) <br /> US-CGH-11-15607 - © Jesse Rivest 2006 *</li>
			<li>Greyhound Through The Rockies (5:46) <br /> US-CGH-11-15608 - © Jesse Rivest 2008 *</li>
			<li>My Ticket Home (4:35) <br /> US-CGH-11-15609 - © Jesse Rivest 2010 +</li>
			<li>Down Again (4:43/5:08) <br /> US-CGH-11-15610 - © Jesse Rivest 2008 *</li>
			<li>Take It or Leave It (4:59/6:01) <br /> US-CGH-11-15611 - © Jesse Rivest 2010 *</li>
		</ol>
		<p>
			* originally released at <span className="italic">Jesse Rivest Podcast</span> on year indicated<br />
			+ originally released on <span className="italic">Live At The Mussel Inn - Feb 19 2010</span><br />
			These ISRCs were generated by CD Baby (distributor) rather than www.jesserivest.com.
		</p>
		<p>
			This recording: catalog number JRIVEST03, © Jesse Rivest 2011, All Rights Reserved<br />
			UPC/EAN: 0885767504899<br />
			Released: February 1, 2011
		</p>

		<h3>Credits</h3>
		<p>
			Track 1: Jesse Rivest (JR) guitars, vocals, mandolin, drum programming; Stu Pedley (SP) bass; Fiona Smythe (FS) violin<br />
			Track 2: JR guitars, vocals; Brendan Schenk (BS) accordion; Peter Cogswell (PC) neat-o percussion; SP bass<br />
			Track 3: JR guitars, vocals; BS banjo; PC neat-o percussion<br />
			Track 4: JR guitars, vocals, elec-gat-organ, mandolin, kalimba; BS accordion<br />
			Track 5: JR guitar, vocals; BS  mandolin; PC neat-o percussion, harmonica<br />
			Recorded and edited by Jesse Rivest.<br />
	        Additional engineering (vocals on Track 1) by Zhenya Topov and James Dunlop at The Blue Room Studio.<br />
	      	Recorded live at Carol and Peter's house in Wellington
			(except electric guitar and bass on Track 2, which was an additional live recording, bedroom-styles)
			(except Tracks 1 and 4, which were recorded piece-wise, pretty much everywhere in Wellington).<br />
	        Mixed by Warwick Donald and Jesse Rivest.<br />
	      	Mastered by Jesse Rivest with Declan Bailey.<br />
	      	Design and layout by Holly Beals.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to the musicians and production contributors for their skill, talent, time, and passion.<br />
			Thanks to Declan Bailey, Zhenya Topov, Stu Pedley, and Lee Prebble for lending equipment.<br />
	  		Thanks to Tessa Rain for helping shape songs (Tracks 1 and 4).<br />
			Thanks to Niels Gedge for the long-term loan of his mandolin (Track 4).<br />
			Thanks to everyone above for their friendship and support!<br />
			Thanks to the linux and open source communities—&quot;55 20 52 4F 43 4B&quot;.<br />
			Thanks to you for listening, caring, and for involving yourself in independent music.
		</p>

		<h3>Extras</h3>
		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST03 everyelsewhere ep back.jpg"
			alt="Jesse Rivest - Everyelsewhere EP - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>
		<h4>Posters</h4>
		<a href={posterWText}>
			<div className="main--centerwrapper">
				<StaticImage
				src="../../images/posters/Everyelsewhere EP poster text.jpg"
				alt="Jesse Rivest - Everyelsewhere EP - poster with text"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={200}
				/>
			</div>
		</a>
		<a href={posterNoText}>
			<div className="main--centerwrapper p">
				<StaticImage
				src="../../images/posters/Everyelsewhere EP poster no text.jpg"
				alt="Jesse Rivest - Everyelsewhere EP - poster without text"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				width={200}
				/>
			</div>
		</a>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				The Romance of Kaslo</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2006
			</h5>
			<p>
				Smoky orange light distant flaming trees<br />
				Smoky orange light on the hazy breeze<br />
				Smoky orange light distant flaming trees<br />
				It's the breath of the BC sun
			</p>
			<p>
				Music in the bay dancing in the sand<br />
				Music in the bay where the mountains stand<br />
				Music in the bay dancing in the sand<br />
				Under stars and a silhouette moon<br />
				Under stars and a silhouette moon
			</p>
			<p>
				Crooked Cafe makes a great big sound<br />
				Crooked Cafe spread the word around<br />
				Crooked Cafe makes a great big sound<br />
				Yes I will return one day
			</p>
			<p>
				Sleeping under stars in the man's back yard<br />
				Sleeping under stars isn't very hard<br />
				Sleeping under stars in the man's back yard<br />
				Hold on to the one you love<br />
				You better hold on to the one you love
			</p>
			<p>
				Olive oil skin and a birch bark dress<br />
				Olive oil skin and my heart's a mess<br />
				Olive oil skin and a birch bark dress<br />
				Girl I'd like to be with you
			</p>
			<p>
				On the side of the road not too far from town<br />
				On the side of the road want to turn around<br />
				On the side of the road and I'm all broke down<br />
				I'm leaving against my will<br />
				I have not yet had my fill<br />
				I'm leaving against my will<br />
				I have not yet had my fill
			</p>
			<p>
				Olive oil skin and a birch bark dress<br />
				Olive oil skin and my heart's a mess
			</p>
			<h4 className="lyrics__title">
				Greyhound Through the Rockies
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2008
			</h5>
			<p>
				I'll miss these trees again<br />
				Before the mountains at the summer's end<br />
				Yellow freckles in the Earth's green skin<br />
				Change it frees us of our regrets and sins
			</p>
			<p>
				The highway swirls in Earth kaleidoscope<br />
				The train tracks climb a most gentle slope<br />
				Cars are passing as a I set afoot<br />
				One after the other will I escape for good
			</p>
			<p>
				Follow a stream until it turns to snow<br />
				Follow a dream that lets you down so slow<br />
				Climb a ridge up to the autumn sky<br />
				I pushed on how I really tried
			</p>
			<p>
				And I tried, and I tried, oh I tried
			</p>
			<p>
				On the bus moving down the road<br />
				To some places I said I'd go<br />
				I won't be back until I feel I'm done<br />
				Don't I know I'm the I'm the selfish one
			</p>
			<p>
				And in review I will miss you<br />
				While I'm gone<br />
				But it won't be long
			</p>
			<p>
				Yes it's true I will miss you<br />
				While I'm gone<br />
				But it won't be long
			</p>
			<p>
				No it won't be long<br />
				No it won't be long<br />
				I will miss you while I'm gone
			</p>
			<h4 className="lyrics__title">
				My Ticket Home</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2010
			</h5>
			<p>
				She met me out at quiz night<br />
				We talked of here and home<br />
				Her chocolate truffle eyes could do me harm
			</p>
			<p>
				She spoke to me in Hebrew<br />
				I thought of her in rhymes<br />
				And when we left we parted at the door<br />
				But oh I wanted more
			</p>
			<p>
				She's my ticket home<br />
				She's everything I've missed<br />
				Memories flash by me now that she's my ticket home
			</p>
			<p>
				She's stuck again on milkshakes<br />
				And those classic cruising cars<br />
				Her hair turns with her skirt when she floats by
			</p>
			<p>
				I'm a tragic minimalist<br />
				Doesn't get me very far<br />
				I bake for her tartes aux poires Bourdaloue<br />
				Dans sa rue
			</p>
			<p>
				She's my ticket home<br />
				She's everything I've missed<br />
				Her laughter rips the map out of my hands when she blows by<br />
				She's my ticket home<br />
				She's everything I've missed<br />
				I'll taste those dusty roads again 'cause<br />
				She's my ticket home
			</p>
			<p>
				Hold on seasons dropping in so fast<br />
				I dreamed a house of perfect viewing glass
			</p>
			<p>
				Now I call her from the west coast<br />
				Say the winters here are mild<br />
				But they don't have those windy city charms
			</p>
			<p>
				She's a drifter with her theories<br />
				Her philosophies run wild<br />
				Now who could tame an ocean from its side<br />
				I think I'll take a dive
			</p>
			<p>
				She's my ticket home<br />
				She's everything I've missed<br />
				Her laughter rips the map out of my hands when she blows by<br />
				She's my ticket home<br />
				She's everything I've missed<br />
				I'll taste those dusty roads again 'cause<br />
				She's my ticket home<br />
				I'll sing my style of song again 'cause<br />
				She's my ticket home
			</p>
			<h4 className="lyrics__title">
				Down Again</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2008
			</h5>
			<p>
				I moped around this town looking for a spark<br />
				In spite of the sun I was in the dark<br />
				Loneliness and cowardice are two sad states of mind<br />
				Happiness could be now just leave the past behind
			</p>
			<p>
				Oh I'm down again<br />
				I've picked up before
			</p>
			<p>
				I bought a ticket for a bus ride out of town<br />
				I couldn't thumb a ride crawling on the ground<br />
				Everybody else seems to have their lives all figured out<br />
				While I follow a map of the sewers in a city of doubt
			</p>
			<p>
				Oh I'm lost again<br />
				I've been found before<br />
				And I am a truth seeker<br />
				Begging at your door
			</p>
			<p>
				And oh I'm old again<br />
				I've been young before<br />
				And I am a silhouette<br />
				Fading from your door
			</p>
			<p>
				And I feel like a cigarette<br />
				Stomped out on the floor<br />
				And I am a shipwreck<br />
				Sunken off your shore<br />
				Sunken off your shore
			</p>
			<h4 className="lyrics__title">
				Take It or Leave It</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2010
			</h5>
			<p>
				They said you can stay<br />
				Only if you pay<br />
				Not just your money<br />
				We want your days<br />
				So take it or leave it
			</p>
			<p>
				She said you should stay<br />
				Only if you want<br />
				These are different stars<br />
				They can be your stars<br />
				So take them or leave them
			</p>
			<p>
				Well I can't sing my way out of this one<br />
				Stuck in my own divide<br />
				With my broken stride<br />
				Who will harmonize with me<br />
				It's been a lonely time just me<br />
				When will I realize just where I wanna be<br />
				So take it or leave it
			</p>
			<p>
				Well there's no moon quite like a new moon
			</p>
			<p>
				Well there's no moon quite like a new moon<br />
				Drawing out its encore<br />
				While stars silently roar<br />
				I've got a backstage pass<br />
				Behind the curtains we laugh<br />
				And if the rain drops fall will they miss me at all<br />
				So take it or leave it
			</p>
			<p>
				She said you should stay<br />
				Only if you want<br />
				These are different stars<br />
				They can be your stars<br />
				So take them or leave them
			</p>
			<p>
				Well I stayed<br />
				So take it or leave it<br />
				Well I stayed
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
